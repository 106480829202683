const KUU_CREATOR_HOST =
  process.env.GATSBY_KUU_CREATOR_HOST || "https://kuucreator.com";
const VIDEO_LINK =
  "https://www.youtube.com/embed/sWoTgCLDEkM?controls=1&loop=1&rel=0&playlist=sWoTgCLDEkM";
const FORM_SPREE =
  process.env.GATSBY_FORM_SPREE || "https://formspree.io/f/xqkgjjow";

const CONTACT_US_URL =
  process.env.GATSBY_CONTACT_US_URL || "https://formspree.io/f/xyyblkyr";

const APPLE_STORE = process.env.GATSBY_APPLE_STORE || "";
const GOOGLE_STORE = process.env.GATSBY_GOOGLE_STORE || "";

const VMAGINE_APPLE_STORE =
  process.env.GATSBY_VMAGINE_APPLE_STORE ||
  "https://apps.apple.com/us/app/vmagine-ai-art-generator/id1659275882";

const VMAGINE_WEBSITE =
  process.env.GATSBY_VMAGINE_WEBSITE || "https://vmagine.ai/";

export {
  CONTACT_US_URL,
  KUU_CREATOR_HOST,
  VIDEO_LINK,
  FORM_SPREE,
  APPLE_STORE,
  GOOGLE_STORE,
  VMAGINE_APPLE_STORE,
  VMAGINE_WEBSITE,
};
