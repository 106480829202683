import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import styled from "@emotion/styled";
import { Divider } from "../style";
import LottieContent from "../Lottie/index";
import { VideoWithThumbnail } from "../VideoContainer";
import cannes from "../../images/Video/Canes.mp4";
import ThumbnailVisible from "../../images/VisibleTrackThumbnail.jpg";
import VisualIcon from "../../images/svg/visualIcon.svg";
import { CustomButtonIcon } from "./buttonIcon";

const VisualContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0em auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  .visual-img {
    width: 100%;
  }

  .lottie-img {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .lottie-container {
    position: absolute;
    top: -60px;
    left: -43px;
    width: 100%;
    z-index: 0;
    transform: scale(1.3);
  }
  .title {
    grid-column: 1 / span 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0em 3em;
    padding-bottom: 2em;
    background-color: #f9f9f9;
    & h1 {
      font-size: clamp(1.5rem, 2.5vw, 2.6rem);
      margin: 0.5em 0em;
    }
  }

  .video-container {
    grid-column: 3 / span 3;
    padding: 3em 0em;
    background-image: linear-gradient(
      to right top,
      #485563,
      #404c59,
      #38434f,
      #313a45,
      #29323c
    );
  }
  .text-description {
    padding: 0em 3em;
    padding-bottom: 2em;
    grid-column: 1 / span 2;
    background-color: #f9f9f9;
  }
  .visual-tech {
    padding-bottom: 2em;
  }
  .image-container {
    grid-column: 3 / span 3;
    background-image: linear-gradient(
      to left top,
      #149ae3,
      #1091d5,
      #0d88c7,
      #0b7fb9,
      #0a76ab
    );
    display: grid;
    grid-auto-flow: column;
    align-items: end;
    text-align: center;
    padding: 2em 1em 0em 1em;
    @media (max-width: 780px) {
      grid-auto-flow: row;
      .visual-tech {
        grid-row: 1;
        height: 350px;
        display: flex;
        align-items: start;
        justify-content: center;
      }
      .lottie-container {
        top: -30%;
        left: -18%;
        width: 100%;
        z-index: 0;
        transform: scale(1.5);
      }
    }
  }
  .logo-container {
    text-align: center;
  }
  @media (max-width: 786px) {
    .title {
      grid-column: 1 / span 5;
      align-items: center;
    }
    .video-container {
      grid-column: 1 / span 5;
      grid-row: 3;
    }
    .text-description {
      grid-column: 1 / span 5;
    }
    .image-container {
      grid-column: 1 / span 5;
    }
  }
`;
const VisualDetection = () => {
  const { t } = useTranslation();
  return (
    <>
      <VisualContainer>
        <div className="title">
          <Fade triggerOnce={true} direction="left">
            <CustomButtonIcon
              icon={
                <VisualIcon
                  style={{
                    maxWidth: 30,
                    width: "100%",
                  }}
                />
              }
            />

            <h1>
              <Trans
                components={[<br />, <strong style={{ color: "#fbb400" }} />]}
              >
                {t("Visual")}
              </Trans>
            </h1>
            <Divider />
          </Fade>
        </div>

        <div className="video-container">
          <Fade triggerOnce={true}>
            <div
              style={{
                maxWidth: 450,
                width: "100%",
                background: "#fff",
                padding: "0.5em",
                border: "1px solid rgba(64, 87, 109, 0.07)",
                borderRadius: "0.5em",
                margin: "0 auto",
              }}
            >
              <VideoWithThumbnail
                thumbnail={ThumbnailVisible}
                videoName={cannes}
              />
            </div>
          </Fade>
        </div>

        <div className="text-description">
          <Fade triggerOnce={true} direction="left">
            <h1
              style={{
                color: "#056B9F",
                fontSize: "clamp(1.2rem , 2.5vw, 1.5rem)",
                fontWeight: 800,
              }}
            >
              {t("Visible Track Project")}
            </h1>
            <p
              style={{
                fontSize: "clamp(1rem, 2.5vw, 1rem)",
                fontWeight: 300,
              }}
            >
              {t("The collaboration")}
            </p>
            <p
              style={{
                fontSize: "clamp(1rem, 2.5vw, 1rem)",
                fontWeight: 300,
              }}
            >
              {t("Multiple AI technologies")}
            </p>
            <ul>
              <li>{t("Cannes Lion 2021")}</li>
              <li>{t("Adman Award")}</li>
            </ul>

            <div className="logo-container">
              <StaticImage
                placeholder="blurred"
                height={65}
                src="../../images/logoAdman.png"
                quality="50"
                alt=""
              />
              <StaticImage
                placeholder="blurred"
                height={65}
                src="../../images/logoCannes.png"
                quality="50"
                alt=""
              />
            </div>
          </Fade>
        </div>

        <div className="image-container">
          <div className="visual-img">
            <div className="lottie-img">
              <StaticImage
                placeholder="blurred"
                imgStyle={{ zIndex: 1 }}
                width={320}
                src="../../images/haftBody.png"
                quality="50"
                alt="KUNANA visible track"
              />
              <div className="lottie-container">
                <LottieContent
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  pathName="VoiceRipple.json"
                />
              </div>
            </div>
          </div>

          <div className="visual-tech">
            <Fade triggerOnce={true}>
              <StaticImage
                placeholder="blurred"
                width={250}
                src="../../images/visualTech.png"
                quality="50"
                alt=""
              />
            </Fade>
          </div>
        </div>
      </VisualContainer>
    </>
  );
};
export default VisualDetection;
