import React from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import { graphql, useStaticQuery } from "gatsby";
import AdKuuCreator from "../../images/Video/AdKuuCreator.mp4";
import KuuLogo from "../../images/svg/KuuLogo.svg";
import Video from "../Mobile/VideoApp";
import { ContainerAppCreator, FrameVideo, TextLink } from "./style";
import { KUU_CREATOR_HOST } from "../config";

const AdvertiseAppCreator = () => {
  const { t } = useTranslation();
  const { blueBackground, elementAd } = useStaticQuery(
    graphql`
      query {
        blueBackground: file(relativePath: { eq: "svg/bgAd.svg" }) {
          id
          publicURL
        }
        elementAd: file(relativePath: { eq: "svg/elementAdCreator.svg" }) {
          id
          publicURL
        }
      }
    `
  );

  return (
    <ContainerAppCreator backgroundImg={blueBackground.publicURL}>
      <div className="wrapper-container">
        <div className="container-text">
          <Fade direction="up" triggerOnce={true}>
            <KuuLogo
              style={{
                maxWidth: 200,
                width: "100%",
                height: "100%",
              }}
            />
            <div>
              <h1
                style={{
                  fontSize: "clamp(1.3rem, 2.5vw, 1.5rem)",
                  fontWeight: 300,
                }}
              >
                <Trans
                  components={[
                    <br />,
                    <strong
                      style={{
                        fontSize: "clamp(1.5rem, 2.5vw, 1.7rem)",
                      }}
                    />,
                    <strong
                      style={{
                        fontSize: "clamp(1.5rem, 2.5vw, 1.7rem)",
                        fontWeight: 800,
                        color: "#fff",
                      }}
                    />,
                  ]}
                >
                  {t("Compose")}
                </Trans>
              </h1>
            </div>
            <TextLink>
              <a
                aria-label="kuu-creator-web"
                target="_blank"
                href={`${KUU_CREATOR_HOST}`}
                rel="noreferrer"
              >
                {t("Go to KUU Creator")}
              </a>
            </TextLink>
          </Fade>
        </div>

        <FrameVideo backgroundImg={elementAd.publicURL}>
          <Fade triggerOnce={true}>
            <Video srcVideo={AdKuuCreator} classStyle="ad-frame" />
          </Fade>
        </FrameVideo>
      </div>
    </ContainerAppCreator>
  );
};

export default AdvertiseAppCreator;
