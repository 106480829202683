import React, { useState } from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { Divider } from "../style";
import { TextContainer } from "./Components/Style";
import DialogContact from "./Components/Dialog";

const ImageGeneration = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <WrapperSmartMulti>
      <div className="text-container">
        <div className="title">
          <Fade triggerOnce={true} direction="up">
            <h1>
              <Trans
                components={[
                  <br />,
                  <strong
                    style={{
                      color: "#fbb400",
                    }}
                  />,
                  <strong
                    style={{
                      fontSize: "clamp(1.2rem, 3.5vw, 1.7rem)",
                      fontWeight: 400,
                    }}
                  />,
                ]}
              >
                {t("image generation")}
              </Trans>
            </h1>
          </Fade>
          <Divider />
          <Fade triggerOnce={true} direction="up">
            <h2
              style={{
                fontSize: "clamp(1rem, 2.5vw, 1.1rem)",
                fontWeight: 300,
              }}
            >
              <Trans components={[<strong />]}>{t("Create any images")}</Trans>
            </h2>

            <h2
              style={{
                fontSize: "clamp(1rem, 2.5vw, 1.1rem)",
                fontWeight: 300,
              }}
            >
              <Trans components={[<br />, <strong />]}>
                {t("We also provide")}
              </Trans>
            </h2>
            <TextContainer
              style={{
                margin: "unset",
              }}
            >
              <div className="container">
                <p>
                  <Trans
                    components={[
                      <br />,
                      <strong
                        style={{
                          fontWeight: 700,
                        }}
                      />,
                    ]}
                  >
                    {t("API Service Generation")}
                  </Trans>
                </p>
                <div style={{ height: "1em" }} />
                <button onClick={handleClickOpen}>{t("Contact us")}</button>
              </div>
            </TextContainer>
            <DialogContact open={open} handleClose={handleClose} />
          </Fade>
        </div>
      </div>
      <div className="smart-image">
        <div
          style={{
            maxWidth: 400,
            width: "100%",
            background: "#fff",
            padding: "1em",
            border: "1px solid rgba(64, 87, 109, 0.07)",
            borderRadius: "0.5em",
            margin: "0 auto",
          }}
        >
          <Fade triggerOnce={true}>
            <StaticImage
              placeholder="blurred"
              height={450}
              src="../../images/Vmagine/Image_generation_ai.png"
              quality="50"
              alt=""
            />
          </Fade>

          <div
            style={{
              backgroundColor: "#E7F2F8",
              padding: "0.5em",
              borderRadius: "0.5em",
              margin: "0.5em 0em",
            }}
          >
            <p
              style={{
                fontSize: "clamp(0.9rem, 2.5vw, 0.9rem)",
                fontWeight: 300,
                textAlign: "center",
                margin: 0,
              }}
            >
              “A photo of an astronaut, wearing a yellow suit, on Mars,
              realistic style”
            </p>
          </div>
        </div>
      </div>
    </WrapperSmartMulti>
  );
};
export default ImageGeneration;

export const WrapperSmartMulti = styled.div`
  position: relative;
  z-index: 1;
  padding: 3em 0em;
  margin: 2em auto;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5em;

  .smart-image {
    display: flex;
    align-items: center;
    grid-column: 3 / span 2;
    padding: 3em 1em;
    position: relative;
    background-image: linear-gradient(
      to right bottom,
      #f0aa19,
      #f6a52e,
      #fba03e,
      #fe9c4b,
      #ff9958
    );
  }
  .text-container {
    align-self: center;
    grid-column: 1 / span 2;
    padding: 0em 3em;
  }
  .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    & h1 {
      font-size: clamp(1.5rem, 2.5vw, 2.6rem);
      font-weight: 700;
      margin: 0.5em 0em;
    }
  }
  @media (max-width: 786px) {
    .smart-image {
      grid-column: 1 / span 5;
    }
    .title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .text-container {
      grid-column: 1 / span 5;
    }
  }
`;
