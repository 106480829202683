import React, { useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";

const VideoContent = styled.div`
  max-width: 250px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .frame {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 26px;
  }
  .ad-frame {
    position: absolute;
    width: 96%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: #000;
    display: flex;
    border-radius: 2em;
    margin: 0 auto;
  }
`;
// eslint-disable-next-line react/prop-types
const Video = ({ srcVideo, classStyle }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoObserver = new IntersectionObserver((entries, videoObserver) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            const videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }
          video.target.load();
          video.target.setAttribute(
            "style",
            "outline: none; opacity: 1; display: block; width: 100%;  max-width: 229px; margin: 0 auto;  height: 100%;  border-radius: 2em;"
          );

          videoObserver.unobserve(video.target);
        }
      });
    });

    if (videoRef.current) videoObserver.observe(videoRef.current);
    return () => {
      videoObserver.disconnect();
    };
  }, []);

  return (
    <VideoContent>
      <StaticImage
        placeholder="tracedSVG"
        imgStyle={{ zIndex: 1 }}
        quality="40"
        src="../../images/iphone.png"
        alt="screen"
      />

      <div className={classStyle ? classStyle : "frame"}>
        <video
          ref={videoRef}
          loading="lazy"
          autoPlay
          muted
          loop
          playsInline
          style={{
            borderRadius: 26,
            display: "block",
            width: "100%",
            maxWidth: 229,
            margin: "0 auto",
            height: "auto",
          }}
        >
          <source data-src={srcVideo} type="video/mp4" />
        </video>
      </div>
    </VideoContent>
  );
};

export default Video;
