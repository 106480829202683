import React, { useRef, useEffect, useState } from "react";
import { PlayCircleOutlineRounded } from "@material-ui/icons";
import styled from "@emotion/styled";

const CustomPlay = styled.button`
  all: unset;
  width: 100px;
  height: 100px;
`;

const VideSource = ({
  videoName,
  paddingTop,
  muted,
  controls,
  autoPlay,
  thumbnail,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoObserver = new IntersectionObserver((entries, videoObserver) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            const videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }
          video.target.load();
          video.target.setAttribute(
            "style",
            "border: 1px solid rgba(64, 87, 109, 0.07); outline: none; opacity: 1; display: block; width: 100%;  margin: 0 auto;  height: 100%;  border-radius: 0.5em;"
          );

          videoObserver.unobserve(video.target);
        }
      });
    });

    if (videoRef.current) videoObserver.observe(videoRef.current);
    return () => {
      videoObserver.disconnect();
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        paddingTop: paddingTop ? paddingTop : "56.25%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "100%",
          width: "100%",
        }}
      >
        <video
          ref={videoRef}
          poster={thumbnail}
          className="frame"
          loading="lazy"
          autoPlay={autoPlay ?? true}
          controls={controls ?? true}
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture={true}
          loop
          playsInline
          preload="none"
          muted={muted}
          style={{
            display: "block",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            borderRadius: "0.5em",
            outline: "none",
          }}
        >
          <source data-src={videoName} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export const VideoFeature = ({
  videoName,
  paddingTop,
  controls,
  muted,
  autoPlay,
  thumbnail,
}) => {
  return (
    <VideSource
      videoName={videoName}
      paddingTop={paddingTop}
      controls={controls}
      muted={muted}
      autoPlay={autoPlay}
      thumbnail={thumbnail}
    />
  );
};

export const VideoWithThumbnail = ({ thumbnail, videoName }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const videoThumbnail = useRef(null);

  const handleVideo = (e) => {
    e.preventDefault();
    setPlayVideo((prev) => !prev);
    if (playVideo) {
      videoThumbnail.current.pause();
    } else {
      videoThumbnail.current.play();
    }
  };
  useEffect(() => {
    const videoObserver = new IntersectionObserver((entries, videoObserver) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            const videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }
          video.target.load();
          video.target.setAttribute(
            "style",
            "border: 1px solid rgba(64, 87, 109, 0.07); outline: none; opacity: 1; display: block; width: 100%;  margin: 0 auto;  height: 100%;  border-radius: 0.5em;"
          );

          videoObserver.unobserve(video.target);
        }
      });
    });

    if (videoThumbnail.current) videoObserver.observe(videoThumbnail.current);
    return () => {
      videoObserver.disconnect();
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        paddingTop: "56.25%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "100%",
          width: "100%",
        }}
      >
        <video
          ref={videoThumbnail}
          poster={thumbnail}
          className="frame"
          loading="lazy"
          loop
          controls={false}
          playsInline
          preload="none"
          style={{
            display: "block",
            width: "100%",
            margin: "0 auto",
            height: "100%",
            borderRadius: "0.5em",
            outline: "none",
          }}
        >
          <source data-src={videoName} type="video/mp4" />
        </video>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            cursor: "pointer",
          }}
          onClick={handleVideo}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: playVideo ? "none" : "flex",
              transition: "all 0.2s ease-in-out",
            }}
          >
            <CustomPlay aria-label="paly-button">
              <PlayCircleOutlineRounded
                style={{
                  width: "100%",
                  height: "100%",
                  color: "#fff",
                }}
              />
            </CustomPlay>
          </div>
        </div>
      </div>
    </div>
  );
};
