import styled from "@emotion/styled";

export const FrameVideo = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 1em 1em;
  grid-column: 3 / span 3;
  ::after {
    content: " ";
    background-image: ${(props) => ` url(${props.backgroundImg})`};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    z-index: -1;
  }
`;

export const AppCreatorText = styled.div`
  & h1 {
    margin: 0.3em 0em;
    background: linear-gradient(
      to left bottom,
      #f9993c,
      #fb9f33,
      #fca628,
      #fcad1a,
      #fbb400
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: clamp(1.4rem, 3vw, 2.5rem);
  }
  & h2 {
    margin: 0.5em 0em;
    font-size: clamp(1rem, 2.5vw, 1.1rem);
    font-weight: 400;
  }
  .body-text-download {
    margin: 0;
    font-weight: 300;
    font-size: clamp(0.9rem, 2.5vw, 0.9rem);
  }
  /* .sub-text {
    font-size: clamp(1rem, 2.5vw, 1.1rem);
    font-weight: 400;
  } */
  @media (max-width: 780px) {
    text-align: center;
    & .app-store {
      justify-content: center;
    }
  }
`;

export const AppCreatorVideoContainer = styled.div`
  position: relative;
  z-index: 1;
  padding: 1em 1em;
  grid-column: 1 / span 3;
  background-image: linear-gradient(
    to right bottom,
    #f0aa19,
    #f6a52e,
    #fba03e,
    #fe9c4b,
    #ff9958
  );
  ::after {
    content: " ";
    background-image: ${(props) => ` url(${props.backgroundImg})`};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    z-index: -1;
  }
`;

export const AppCreatorVideo = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 5em auto;
  margin-bottom: 5em;
  display: grid;
  /* grid-gap: 1em; */
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr)); */
  .text-app-creator {
    grid-column: 4 / span 2;
    width: 100%;
    background-color: #f9f9f9;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em 3em;
  }
  @media (max-width: 780px) {
    ${AppCreatorVideoContainer} {
      grid-column: 1 / span 5;
    }
    .text-app-creator {
      grid-column: 1 / span 5;
      text-align: center;
      align-items: center;
    }
  }
`;

export const TextLink = styled.div`
  color: #fff;
  justify-content: center;
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  box-shadow: rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px;
  background-image: linear-gradient(
    to right bottom,
    #f09819,
    #f88829,
    #fe7839,
    #ff6748,
    #ff5858
  );
  max-width: 200px;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  font-size: clamp(0.9rem, 2.5vw, 0.9rem);
  & a {
    all: unset;
  }
  :hover,
  a:hover {
    text-decoration: underline;
  }
`;
export const ContainerAppCreator = styled.div`
  position: relative;
  z-index: 1;
  ::after {
    content: " ";
    background-image: ${(props) => ` url(${props.backgroundImg})`};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -1;
    @media (max-width: 767px) {
      background-position: left;
      height: 100%;
      top: 0;
    }
  }
  .container-text {
    display: inline-block;
    /* padding: 0em 0em 0em 1em; */
    grid-column: 1 / span 2;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    .container-text {
      align-self: center;
      margin: 0 auto;
    }
    ${TextLink} {
      margin: 0 auto;
    }
  }

  .wrapper-container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-gap: 1em;
    grid-auto-flow: column;
    align-items: center;
    padding: 2em 0.5em;
    grid-template-columns: repeat(5, 1fr);
  }

  .card-container {
    max-width: 250px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  @media (max-width: 780px) {
    .container-text {
      grid-column: 1 / span 5;
      text-align: center;
      & strong {
        color: #000 !important;
      }
    }
    ${FrameVideo} {
      grid-column: 1 / span 5;
    }
  }
`;
