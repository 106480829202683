import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Slider from "./CompareSlider/index";

const ImageContainer = [
  {
    id: 0,
    imageBg: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Model/Background.jpg"
        quality="50"
        alt=""
      />
    ),
    model: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Model/Model.png"
        quality="50"
        alt=""
      />
    ),
  },
  {
    id: 1,
    imageBg: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Product/Background.jpg"
        quality="50"
        alt=""
      />
    ),
    model: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Product/Bag.png"
        quality="50"
        alt=""
      />
    ),
  },
  {
    id: 2,
    imageBg: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Car/Background.jpg"
        quality="50"
        alt=""
      />
    ),
    model: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Car/Car.png"
        quality="50"
        alt=""
      />
    ),
  },
  {
    id: 3,
    imageBg: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Dessert/Background.jpg"
        quality="50"
        alt=""
      />
    ),
    model: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Dessert/Cupcake.png"
        quality="50"
        alt=""
      />
    ),
  },
  {
    id: 4,
    imageBg: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Animal/Background.jpg"
        quality="50"
        alt=""
      />
    ),
    model: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Animal/Dog.png"
        quality="50"
        alt=""
      />
    ),
  },
  {
    id: 5,
    imageBg: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Plant/Background.jpg"
        quality="50"
        alt=""
      />
    ),
    model: (
      <StaticImage
        placeholder="BLURRED"
        width={250}
        imgStyle={{
          userSelect: "none",
          borderRadius: "0.2em",
        }}
        src="../images/RemovalBG/Plant/Plant.png"
        quality="50"
        alt=""
      />
    ),
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ padding: "1em" }}>
          <>{children}</>
        </div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
const AntTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: "#Fbb400",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 60,
    padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 10,
    color: "#000",
    fontFamily: ["Nunito Sans", "prompt", "sans-serif"].join(","),
    "&:hover": {
      color: "#Fbb400",
      opacity: 1,
    },
    "&$selected": {
      color: "#Fbb400",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#Fbb400",
    },
  },
  selected: {},
  wrapper: {
    backgroundColor: "#ececec",
    padding: " 0.1em 1em",
    borderRadius: "1.5em",
  },
}))((props) => <Tab disableRipple {...props} />);

const ContainerTab = styled.div`
  .mobile {
    display: none;
    @media (max-width: 780px) {
      flex-grow: 1;
      display: block;
    }
  }
  .desktop {
    display: block;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 700px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    grid-gap: 1em;
    @media (max-width: 780px) {
      display: none;
    }
  }

  .demo1 {
    max-width: 560px;
    margin: 0 auto;
    background-color: transparent;
  }
`;

export default function ScrollableTabsButtonAuto() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ContainerTab>
      <div className="mobile">
        <div className="demo1">
          <AntTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs example"
          >
            <AntTab label={t("Model")} {...a11yProps(0)} />
            <AntTab label={t("Product")} {...a11yProps(1)} />
            <AntTab label={t("Car")} {...a11yProps(2)} />
            <AntTab label={t("Dessert")} {...a11yProps(3)} />
            <AntTab label={t("Animal")} {...a11yProps(4)} />
            <AntTab label={t("Plant")} {...a11yProps(5)} />
          </AntTabs>
        </div>

        {ImageContainer.map((e) => {
          return (
            <TabPanel key={e.id} value={value} index={e.id}>
              <Slider imageBg={e.imageBg} model={e.model} />
            </TabPanel>
          );
        })}
      </div>
      <div className="desktop">
        {ImageContainer.map((e) => {
          return (
            <div key={e.id}>
              <Slider imageBg={e.imageBg} model={e.model} />
            </div>
          );
        })}
      </div>
    </ContainerTab>
  );
}
