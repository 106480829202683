import styled from "@emotion/styled";

export const TextContainer = styled.div`
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  .container {
    max-width: 450px;
    width: 100%;
    display: grid;
    grid-auto-flow: row;

    & p {
      font-size: clamp(0.9rem, 2.5vw, 1.1rem);
    }
  }

  @media (max-width: 780px) {
    .container {
      justify-items: center;
      margin: 0 auto;

      & h1 {
        text-align: center;
      }
      & p {
        text-align: center;
      }
    }
  }

  & h1 {
    font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    font-weight: 700;
  }
  & button {
    all: unset;
    text-align: center;
    font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    background-image: linear-gradient(
      to right bottom,
      #f09819,
      #f88829,
      #fe7839,
      #ff6748,
      #ff5858
    );
    max-width: 80px;
    padding: 0.5em 1em;
    color: #fff;
    text-align: center;
    border-radius: 0.5em;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const ContainerBgRemoval = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 4em auto;
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  .text-removal {
    grid-column: 4 / span 2;
    align-self: center;
    padding: 0em 3em;
  }
  .title {
    display: flex;
    flex-direction: column;
  }
  .gallery-image {
    height: 100%;
    width: 100%;
    padding: 2em 1em;
    grid-column: 1 / span 3;
    background-image: linear-gradient(
      to left top,
      #41bcff,
      #3cb4f4,
      #37ace9,
      #32a4de,
      #2d9cd3
    );
  }
  @media (max-width: 780px) {
    .text-removal {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-column: 1 / span 5;
      padding-bottom: 2em;
    }
    .gallery-image {
      grid-column: 1 / span 5;
      grid-row: 2;
    }
    .title {
      align-items: center;
    }
  }
`;
