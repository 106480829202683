import React from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";
import Video from "../Mobile/VideoApp";
import Space from "../../images/Video/Vmagine/space.mp4";
import AppStoreButton from "./AppStoreButton";
import { TextLink } from "../Mobile/style";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="first-container">
        <div className="element">
          <StaticImage
            placeholder="blurred"
            width={500}
            src="../../images/Vmagine/element.webp"
            quality="70"
            alt=""
          />
        </div>

        <div className="container-video">
          <Video srcVideo={Space} />
        </div>
      </div>
      <div className="second-container">
        <Fade triggerOnce={true} direction="up">
          <div>
            <StaticImage
              placeholder="blurred"
              width={250}
              src="../../images/Vmagine/vmagine_name_logo.png"
              quality="50"
              alt=""
            />
          </div>
          <h1>{t("AI Generate art")}</h1>
          <h2>
            <Trans
              components={[
                <br />,
                <strong
                  style={{
                    fontSize: "clamp(1.2rem, 3.5vw, 1.2rem)",
                    fontWeight: 500,
                  }}
                />,
              ]}
            >
              {t("Create & Edit")}
            </Trans>
          </h2>
          <AppStoreButton />
        </Fade>
      </div>
    </Container>
  );
};

export default Banner;

export const Container = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  padding: 0em 1em;
  padding-top: 3em;
  grid-gap: 1.5em;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 780px) {
    .first-container {
      grid-column: 1 / span 2;
      order: 1;
    }
    .second-container {
      grid-column: 1 / span 2;
      align-items: center !important;
      text-align: center;
      margin: 0 auto;
      gap: 0.3em;

      ${TextLink} {
        margin: 0 auto;
      }
      & h2 {
        margin: 0 auto;
      }
      & p {
        align-self: center;
      }
    }
  }

  .first-container {
    margin: 0 auto;
    position: relative;
    width: 100%;

    max-width: 500px;
    aspect-ratio: 1;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .element {
    position: absolute;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
  }
  .container-video {
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
  }
  .second-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    color: #fff;
    & h1 {
      font-size: clamp(1.7rem, 3.5vw, 1.8rem);
      margin: 0;
    }
    & h2 {
      line-height: 1.5;
      font-size: clamp(1rem, 3.5vw, 1rem);
      font-weight: 300;
    }
  }
`;
