import { Dialog, IconButton, DialogContent } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { ContactUs } from "../../ContactUs/index";

DialogContact.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function DialogContact({ open, handleClose }) {
  const { t } = useTranslation();
  return (
    <Dialog
      PaperProps={{
        style: { width: "100%", margin: 20 },
      }}
      maxWidth={"sm"}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5em 1em",
        }}
      >
        <p
          style={{
            margin: 0,
            fontWeight: 700,
            fontSize: "clamp(1.3rem, 2.5vw, 1.3rem)",
          }}
        >
          {t("Contact us")}
        </p>
        <IconButton onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <DialogContent
        dividers
        style={{
          padding: "0",
        }}
      >
        <div>
          <ContactUs />
        </div>
      </DialogContent>
    </Dialog>
  );
}
