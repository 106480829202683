import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import ElementContainer from "./ElementContainer";
import { WrapperElement, HeaderPortfolio, SubHeader } from "./styles";
import { WrapperQuote } from "../Home/styles";

const PortfolioContent = () => {
  const { t } = useTranslation();
  const { quoteElement } = useStaticQuery(
    graphql`
      query {
        quoteElement: file(
          relativePath: { eq: "svg/QuoteCompanyElement.svg" }
        ) {
          id
          publicURL
        }
      }
    `
  );

  return (
    <HeaderPortfolio>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "2em",
        }}
      >
        <WrapperQuote backgroundImg={quoteElement.publicURL}>
          <Fade direction="up" triggerOnce={true}>
            <h1>
              <Trans
                components={[
                  <br />,
                  <strong
                    style={{
                      fontSize: "clamp(1rem, 2.5vw, 1.8rem)",
                    }}
                  />,
                  <strong
                    style={{
                      fontWeight: 900,
                      color: "#fbb400",
                    }}
                  />,
                ]}
              >
                {t("kunana company")}
              </Trans>
            </h1>
          </Fade>
        </WrapperQuote>
        <SubHeader>
          <Fade direction="up" triggerOnce={true}>
            <h1>
              <Trans
                components={[
                  <b
                    style={{
                      color: "#fbb400",
                    }}
                  />,
                ]}
              >
                {t("Port")}
              </Trans>
            </h1>
            <h2>
              <Trans components={[<br />]}>{t("Learn how KUNANA AI")}</Trans>
            </h2>
          </Fade>
        </SubHeader>
      </div>
      <WrapperElement>
        <ElementContainer />
      </WrapperElement>
    </HeaderPortfolio>
  );
};
export default PortfolioContent;
