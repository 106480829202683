import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Fade as ReactFade } from "react-awesome-reveal";
import { StaticImage } from "gatsby-plugin-image";
import { PlayArrowRounded } from "@material-ui/icons";
import { Divider } from "../style";
import { Backdrop, Modal, Fade } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import { VideoFeature } from "../VideoContainer";
import FaceSwapVideo from "../../images/Video/FaceSwap.mp4";
import { MissingPersonPublication } from "./Publication";
import { CustomButtonIcon } from "./buttonIcon";
import DeepIcon from "../../images/svg/DeepIcon.svg";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "transparent",
    border: "2px solid #000",
  },
}));

const FaceSwapContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  align-items: center;

  .face-swap-image {
    grid-column: 1 / span 3;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    ::before {
      content: " ";
      position: absolute;
      background-image: ${(props) => ` url(${props.backgroundImg})`};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .text-header {
    grid-column: 4 / span 2;
    padding: 1em 3em;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    & h1 {
      font-size: clamp(1rem, 2.5vw, 1.1rem);
      font-weight: 600;
    }
    & h2 {
      font-size: clamp(1rem, 2.5vw, 1rem);
      font-weight: 300;
    }
    & li {
      font-size: clamp(1rem, 2.5vw, 1rem);
      font-weight: 600;
    }
  }
  .title {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 780px) {
    .face-swap-image {
      grid-column: 1 / span 5;
    }
    .text-header {
      grid-column: 1 / span 5;
      grid-row: 1;
    }
    .title {
      align-items: center;
      text-align: center;
    }
  }
`;

const CustomButton = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 2em 0em;

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
  }
  @media (max-width: 600px) {
    .button-container {
      flex-direction: column;
    }
  }

  .button-video {
    background-color: #fff;
    border: 1px solid rgba(64, 87, 109, 0.2);
    box-shadow: rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px;
    max-width: 240px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0.5em;
    gap: 1em;
    font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    transition: box-shadow 0.3s;
    border-radius: 0.5em;
    cursor: pointer;
    :hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
  }
`;

const FaceSwap = ({ backgroundImg }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        margin: "2em auto",
        width: "100%",
        height: "100%",
        padding: "3em 0em",
      }}
    >
      <FaceSwapContainer backgroundImg={backgroundImg}>
        <div className="face-swap-image">
          <ReactFade triggerOnce={true}>
            <div
              style={{
                maxWidth: 600,
                width: "100%",
                padding: "3em 0em",
              }}
            >
              <StaticImage
                width={600}
                src="../../images/DeepLearning.png"
                quality="50"
                alt=""
                placeholder="blurred"
              />
            </div>
          </ReactFade>
        </div>

        <div className="text-header">
          <div className="title">
            <ReactFade direction="up" triggerOnce={true}>
              <CustomButtonIcon
                icon={
                  <DeepIcon
                    style={{
                      maxWidth: 30,
                      width: "100%",
                    }}
                  />
                }
              />
              <h1
                style={{
                  fontSize: "clamp(1.5rem, 3.5vw, 2.2rem)",
                  fontWeight: 700,
                  margin: "0.5em 0em",
                }}
              >
                <Trans
                  components={[
                    <br />,
                    <strong
                      style={{
                        color: "#fbb400",
                      }}
                    />,
                    <strong
                      style={{
                        fontSize: "clamp(1.2rem, 3.5vw, 1.7rem)",
                        fontWeight: 400,
                      }}
                    />,
                  ]}
                >
                  {t("Face Swap")}
                </Trans>
              </h1>
              <Divider />
            </ReactFade>
          </div>
          <ReactFade direction="up" triggerOnce={true}>
            <h1 style={{ color: "#056b9f" }}>
              {t("Missing Person Reporter Project")}
            </h1>
            <h2>{t("A collaboration")}</h2>
            <ul>
              <li>{t("Cannes Lion 2021")}</li>
              <li>{t("Adman Award 2021 Winner")}</li>
            </ul>
            <h2 className="text-description">
              <Trans components={[<br />]}>
                {t("The project was created")}
              </Trans>
            </h2>
          </ReactFade>
        </div>
      </FaceSwapContainer>

      <MissingPersonPublication />

      <CustomButton>
        <div className="button-container" onClick={handleOpen}>
          <ReactFade triggerOnce={true}>
            <div className="button-video">
              <div
                style={{
                  backgroundColor: "#ff0000",
                  padding: "1em",
                  display: "flex",
                  borderRadius: "0.5em",
                }}
              >
                <PlayArrowRounded style={{ color: "#fff" }} />
              </div>
              {t("Missing Person Reporters Campaign")}
            </div>

            <div
              style={{
                textAlign: "end",
              }}
            >
              <StaticImage
                placeholder="blurred"
                height={65}
                src="../../images/logoAdman.png"
                quality="50"
                alt=""
              />
              <StaticImage
                placeholder="blurred"
                height={65}
                src="../../images/logoCannes.png"
                quality="50"
                alt=""
              />
            </div>
          </ReactFade>
        </div>
      </CustomButton>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            style={{
              position: "relative",
              maxWidth: 1100,
              width: "100%",
              padding: "1em",
            }}
          >
            <CancelIcon
              onClick={handleClose}
              style={{
                position: "absolute",
                top: -10,
                right: 0,
                cursor: "pointer",
              }}
            />
            <VideoFeature
              controls={true}
              autoPlay={true}
              muted={false}
              videoName={FaceSwapVideo}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default FaceSwap;
