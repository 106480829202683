import React from "react";
import { Fade } from "react-awesome-reveal";
import { Card, InnerCard } from "./styles";

const CustomCard = (props) => {
  const { icon, header, subHeader, description } = props;
  return (
    <Card>
      <Fade
        triggerOnce={true}
        style={{
          height: "100%",
        }}
      >
        <InnerCard>
          <div
            style={{
              margin: "0 auto",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            {icon}
          </div>
          <div>
            <h1
              style={{
                fontSize: "clamp(1rem, 2.5vw, 1.2rem)",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              {header}
              <strong
                style={{
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                }}
              >
                {subHeader}
              </strong>
            </h1>
            <h2
              style={{
                fontSize: "clamp(1rem, 2.5vw, 1rem)",
                fontWeight: 300,
              }}
            >
              {description}
            </h2>
          </div>
        </InnerCard>
      </Fade>
    </Card>
  );
};

export default CustomCard;
