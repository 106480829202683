import styled from "@emotion/styled";

export const Card = styled.div`
  padding: 12px;
  box-sizing: border-box;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
    z-index: 1;
  }
  @media (max-width: 390px) {
    :hover {
      transform: scale(1);
    }
  }
  @media (min-width: 960px) {
    flex-grow: 0;
    flex-basis: 25%;
  }
  @media (max-width: 960px) {
    flex-grow: 0;
    flex-basis: 50%;
  }

  @media (max-width: 600px) {
    flex-grow: 0;
    flex-basis: 90%;
    margin: auto;
  }
`;

export const InnerCard = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  background-color: #f3fbff;
  border-radius: 15px;
  height: 100%;
  padding: 1em;
  border: 1px solid rgba(64, 87, 109, 0.07);
`;

export const ContainerInfo = styled.div`
  margin-top: 5em;
  padding-top: 10em;
  padding-bottom: 2em;
  position: relative;
  z-index: 1;
  ::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(100% 0, 100% 100%, 50% 95%, 0% 100%, 0% 0%);
    /* background-color: #2598da; */
    background-image: linear-gradient(
      to right,
      #b7e3ff,
      #98d0f5,
      #78bdec,
      #55abe3,
      #2598da
    );
    width: 100%;
    height: 75%;
    z-index: -1;
  }

  & h1 {
    text-align: center;
    font-size: clamp(1.4rem, 3vw, 2.9rem);
  }
  & h2 {
    font-size: clamp(1rem, 2.5vw, 1.1rem);
    font-weight: 400;
    text-indent: 2em;
  }
  .container-company {
    display: grid;
    grid-auto-flow: row;
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 0em 1em;
    grid-gap: 1em;
    ::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: 1px solid rgba(64, 87, 109, 0.07);
      border-radius: 0.5em;
      box-shadow: 0 0 11px rgb(33 33 33 / 20%);
      z-index: -1;
    }
  }
`;

export const ContainerMeetTeam = styled.div`
  margin-top: 2em;
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #f9f9f9;
  & h1 {
    text-align: center;
    font-size: clamp(1.4rem, 3vw, 2.9rem);
  }
  & h2 {
    font-size: clamp(1rem, 2.5vw, 1.1rem);
    font-weight: 400;
    text-indent: 2em;
  }

  .wrapper {
    display: grid;
    grid-auto-flow: column;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0em 1em;
  }
  .container-gallery {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    grid-gap: 0.5em;
    max-width: 450px;
    width: 100%;
  }
  .text-container {
    max-width: 600px;
    width: 100%;
    padding: 0em 1em;
    margin: 0 auto;
  }
  .card-image {
    transition: all 0.2s ease-in-out;
    :hover {
      transform: scale(1.2);
      z-index: 1;
      box-shadow: 0 0 11px rgb(33 33 33 / 20%);
    }
  }

  @media (max-width: 600px) {
    .text-container {
      margin-bottom: 2em;
    }
    .wrapper {
      grid-auto-flow: row;
    }
    .container-gallery {
      margin: 0 auto;
    }
    .card-image {
      :hover {
        transform: scale(1);
        box-shadow: none;
      }
    }
  }
`;
