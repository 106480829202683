import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import { Divider } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import { TypographyGradient } from "./Style/index";

const RecreateTitle = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
        maxWidth: 1000,
        margin: "0 auto",
        padding: "0em 1em",
      }}
    >
      <Divider
        variant="middle"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.12)",
          marginBottom: "2em",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Fade triggerOnce={true}>
          <div
            style={{
              background: "#000",
              maxWidth: 100,
              width: "100%",
              height: "100%",
              margin: "0 auto",
              textAlign: "center",
              border: "1px solid rgba(255, 255, 255, 0.12)",
              borderRadius: "0.5em",
            }}
          >
            <StaticImage
              placeholder="blurred"
              width={100}
              src="../../images/Vmagine/icon_recreate.png"
              quality="50"
              alt=""
            />
          </div>

          <TypographyGradient
            text={t("AI Recreate")}
            linearGradient="linear-gradient(130deg, rgba(103,46,253,1) 30%, rgba(64,140,230,1) 60%, rgba(37,193,219,1) 100%)"
          />
          <p
            style={{
              margin: 0,
              fontSize: "clamp(1rem, 2.5vw, 1.3rem)",
              color: "#fff",
            }}
          >
            {t("take your AI Art to the next level")}
          </p>
        </Fade>
      </div>
    </div>
  );
};
export default RecreateTitle;
