import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import VisualDetection from "./VisualDetection";
import { VisualDetectionPublication } from "./Publication";
import BackgroundRemoval from "./BackgroundRemoval";
import SmartMultilingual from "./SmartMultilingual";
import ImageGeneration from "./ImageGeneration";
import FaceSwap from "./FaceSwap";
import SaveVoiceProject from "./SaveMyVoiceProject";
import TitleArticle from "./Components/TitleArticle";

const ShowCaseContainer = () => {
  const { t } = useTranslation();
  const { elementRectangle } = useStaticQuery(
    graphql`
      query {
        elementRectangle: file(relativePath: { eq: "svg/bgDeepFake.svg" }) {
          id
          publicURL
        }
      }
    `
  );

  return (
    <>
      <TitleArticle title={t("Kunana AI")} />

      {/* Image Generation AI ================== */}
      <ImageGeneration />

      {/* Background Removal AI ================== */}
      <BackgroundRemoval />

      {/* Smart Multilingual ================== */}
      <SmartMultilingual />

      <TitleArticle title={t("Clients")} />

      {/* Face Swap ================== */}
      <FaceSwap backgroundImg={elementRectangle.publicURL} />

      {/* Visual Detection ================== */}
      <VisualDetection />

      {/* Information ================== */}
      <VisualDetectionPublication />

      {/* Save My Voice ================== */}
      <SaveVoiceProject />
    </>
  );
};

export default ShowCaseContainer;
