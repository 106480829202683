import React from "react";
import styled from "@emotion/styled";

const ButtonCard = styled.div`
  width: 60px;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
  box-shadow: 16px 6px 10px 0 rgba(14, 31, 53, 0.06),
    0 36px 52px -4px rgba(14, 31, 53, 0.1);
  border: 1px solid rgba(64, 87, 109, 0.07);
`;

export const CustomButtonIcon = ({ icon }) => {
  return (
    <div
      style={{
        padding: "1em 0em",
      }}
    >
      <ButtonCard>{icon}</ButtonCard>
    </div>
  );
};
