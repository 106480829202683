import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import AppStoreLogo from "../../images/svg/AppStoreLogo.svg";
import { TextLink } from "../Mobile/style";
import { VMAGINE_APPLE_STORE, VMAGINE_WEBSITE } from "../config";

const AppStoreButton = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <a
        aria-label="vmagine-apple-store-link"
        href={`${VMAGINE_APPLE_STORE}`}
        target="_blank"
        style={{ textDecoration: "none" }}
        rel="noreferrer"
      >
        <AppStoreLogo style={{ maxWidth: 250, width: "100%" }} />
      </a>
      <p
        style={{
          margin: 0,
          padding: "0.5em 0em",
          fontWeight: 300,
          color: "#fff",
        }}
      >
        {t("Download free today")}
      </p>
      {props.hiddenButton ? null : (
        <TextLink>
          <a
            aria-label="vmagine-ai-art-web"
            target="_blank"
            href={`${VMAGINE_WEBSITE}`}
            rel="noreferrer"
          >
            {t("Go to Vmagine")}
          </a>
        </TextLink>
      )}
    </>
  );
};

AppStoreButton.defaultProps = {
  hiddenButton: false,
};
export default AppStoreButton;
