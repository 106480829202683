import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import GoogleStoreLogo from "../images/svg/GoogleStoreLogo.svg";
import AppStoreLogo from "../images/svg/AppStoreLogo.svg";
import { APPLE_STORE, GOOGLE_STORE } from "./config";

const AppStore = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        className="app-store"
        style={{
          display: "flex",
          gap: "1em",
        }}
      >
        <a
          aria-label="apple-store-link"
          href={`${APPLE_STORE}`}
          target="_blank"
          style={{ textDecoration: "none" }}
          rel="noreferrer"
        >
          <AppStoreLogo style={{ maxWidth: 150, width: "100%" }} />
        </a>
        <a
          aria-label="google-store-link"
          href={`${GOOGLE_STORE}`}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <GoogleStoreLogo style={{ maxWidth: 150, width: "100%" }} />
        </a>
      </div>
      <div className="body-text-download">{t("Download free today")}</div>
    </div>
  );
};

export default AppStore;
