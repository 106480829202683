import { Typography } from "@material-ui/core";
import styled from "@emotion/styled";

export const TypographyGradient = (props) => {
  const { text, linearGradient, gutterBottom } = props;
  return (
    <Typography
      variant="h2"
      gutterBottom={gutterBottom ?? true}
      style={{
        fontSize: "clamp(1.8rem, 3vw, 2.2rem)",
        fontWeight: 700,
        backgroundImage:
          linearGradient ?? "linear-gradient(45deg, #F09819, #FFB458)",
        backgroundSize: "100%",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        MozBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        MozTextFillColor: "transparent",
      }}
    >
      {text}
    </Typography>
  );
};

export const ContainerVideo = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;

  max-width: 500px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  order: ${(props) => (props.order ? 1 : 0)};
`;

export const ContainerTypography = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #fff;
  max-width: 500px;
  margin: 0 auto;
  & h1 {
    font-size: clamp(1.7rem, 3.5vw, 1.8rem);
    margin: 0;
  }
  & h2 {
    font-size: clamp(1.7rem, 3.5vw, 1.8rem);
    margin: 0;
  }
  & p {
    line-height: 1.5;
    font-size: clamp(1.1rem, 3.5vw, 1.1rem);
    font-weight: 300;
  }
`;

export const ContainerGrid = styled.div`
  max-width: 1000px;
  width: 100%;
  display: grid;
  padding: 1.5em 1em;
  margin: 0 auto;
  grid-gap: 1.5em;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  background: ${(props) => props.backgroundGradient[0]};

  @media (max-width: 780px) {
    max-width: 500px;
    background: ${(props) => props.backgroundGradient[1]};
    ${ContainerVideo} {
      grid-column: 1 / span 2;
      order: 1;
    }
    ${ContainerTypography} {
      grid-column: 1 / span 2;
      align-items: center !important;
      text-align: center;
      margin: 0 auto;

      & h2 {
        margin: 0 auto;
      }
      & p {
        align-self: center;
      }
    }
  }
  .container-video {
    width: 100%;
    margin: 0 auto;
  }
`;
