import React from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { ButtonCustom, InformationContainer } from "./style";

export const VisualDetectionPublication = () => {
  const { t } = useTranslation();
  return (
    <>
      <InformationContainer
        bgColor={
          "linear-gradient(to left bottom, #f69724, #f8a025, #faa926, #fcb328, #fdbc2b)"
        }
      >
        <div className="wrapper-container">
          <Fade direction="left" triggerOnce={true}>
            <div style={{ textAlign: "center" }}>
              <StaticImage
                placeholder="blurred"
                width={450}
                src="../../images/visibleImg.jpg"
                quality="50"
                alt=""
                imgStyle={{
                  borderRadius: "0.8em",
                }}
              />
            </div>
          </Fade>

          <Fade direction="right" triggerOnce={true}>
            <div className="text-information">
              <div className="text-container">
                <h1
                  style={{
                    fontSize: "clamp(1.2rem, 2.5vw, 1.6rem)",
                    margin: 0,
                    color: "#fbb400",
                  }}
                >
                  {t("Visible Track")}
                </h1>
                <h2
                  style={{
                    fontSize: "clamp(1rem, 2.5vw, 1.1rem)",
                  }}
                >
                  {t("The Technology")}
                </h2>
              </div>
              <ButtonCustom
                target="_blank"
                href="https://adaddictth.com/works/Visible-Track"
              >
                {t("Read more")}
              </ButtonCustom>
            </div>
          </Fade>
        </div>
      </InformationContainer>
    </>
  );
};

export const MissingPersonPublication = () => {
  const { t } = useTranslation();
  return (
    <InformationContainer
      top="0"
      topAfter="-15px"
      height="100%"
      bgColor={
        "linear-gradient(to right top,#485563,#404c59,#38434f,#313a45,#29323c)"
      }
    >
      <div className="wrapper-container-missing">
        <Fade direction="left" triggerOnce={true}>
          <div
            style={{
              margin: "0 auto",
              alignSelf: "center",
            }}
          >
            <StaticImage
              height={500}
              placeholder="blurred"
              src="../../images/informationMissing.png"
              quality="50"
              alt=""
              imgStyle={{
                borderRadius: "0.8em",
              }}
            />
          </div>
        </Fade>

        <Fade direction="right" triggerOnce={true}>
          <div className="text-information">
            <div
              style={{
                maxWidth: 350,
                width: "100%",
                margin: "0 auto",
                padding: "4em 1em 0em 1em",
              }}
            >
              <h1
                style={{
                  fontSize: "clamp(1.1rem, 2.5vw, 1.3rem)",
                  margin: 0,
                  color: "#fbb400",
                }}
              >
                {t("Missing Person Reporter Project")}
              </h1>
              <h2
                style={{
                  fontSize: "clamp(0.9rem, 2.5vw, 0.9rem)",
                }}
              >
                <Trans components={[<br />]}>{t("The Combination")}</Trans>
              </h2>
            </div>
            <ButtonCustom
              target="_blank"
              href="https://www.facebook.com/MarketingOopsdotcom/videos/630112198207215"
            >
              {t("Watch Video")}
            </ButtonCustom>
          </div>
        </Fade>
      </div>
    </InformationContainer>
  );
};
