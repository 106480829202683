import React, { useState } from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import ScrollableTabsButtonAuto from "../Tabs";
import RemoveIcon from "../../images/svg/removeIcon.svg";
import { CustomButtonIcon } from "./buttonIcon";
import { Divider } from "../style";
import { TextContainer, ContainerBgRemoval } from "./Components/Style";
import DialogContact from "./Components/Dialog";

const BackgroundRemoval = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ContainerBgRemoval>
        <div className="gallery-image">
          <Fade triggerOnce={true}>
            <ScrollableTabsButtonAuto />
          </Fade>
        </div>

        <div className="text-removal">
          <Fade direction="up" triggerOnce={true}>
            <div className="title">
              <CustomButtonIcon
                icon={
                  <RemoveIcon
                    style={{
                      maxWidth: 25,
                      width: "100%",
                    }}
                  />
                }
              />
              <h1
                style={{
                  fontSize: "clamp(1.5rem, 2.5vw, 2.4rem)",
                  fontWeight: 500,
                  color: "#000",
                  margin: "0.5em 0em",
                }}
              >
                <Trans
                  components={[
                    <br />,
                    <strong
                      style={{
                        fontSize: "clamp(1.2rem, 3.5vw, 1.7rem)",
                        fontWeight: 400,
                      }}
                    />,
                    <strong />,
                    <strong style={{ color: "#fbb400" }} />,
                  ]}
                >
                  {t("High Precision")}
                </Trans>
              </h1>
              <Divider />
            </div>
            <Fade direction="up" triggerOnce={true}>
              <h2
                style={{
                  fontSize: "clamp(1rem, 2.5vw, 1.1rem)",
                  fontWeight: 300,
                }}
              >
                {t("Our state-of-the-art")}
              </h2>
            </Fade>
            <TextContainer>
              <div className="container">
                <p>
                  <Trans
                    components={[
                      <br />,
                      <strong
                        style={{
                          fontWeight: 700,
                        }}
                      />,
                    ]}
                  >
                    {t("Contact us if you")}
                  </Trans>
                </p>
                <button onClick={handleClickOpen}>{t("Contact us")}</button>
              </div>
            </TextContainer>
          </Fade>
        </div>
      </ContainerBgRemoval>

      <DialogContact open={open} handleClose={handleClose} />
    </>
  );
};

export default BackgroundRemoval;
