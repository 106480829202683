import React from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import { StaticImage } from "gatsby-plugin-image";
import { ContainerInnovation } from "./styles";

const Innovation = () => {
  const { t } = useTranslation();
  return (
    <ContainerInnovation>
      <Fade direction="up" triggerOnce={true}>
        <h1
          style={{
            textAlign: "center",
            margin: "0 auto",
            marginBottom: "1em",
            fontSize: "clamp(1rem, 2.5vw, 1.3rem)",
            fontWeight: 300,
            padding: "0em 0.5em",
          }}
        >
          <Trans components={[<br />, <strong />]}>
            {t("Kunana innovations combine")}
          </Trans>
        </h1>
      </Fade>

      <div
        style={{
          margin: "0 auto",
          padding: "1em",
          zIndex: 1,
        }}
      >
        <Fade direction="up" triggerOnce={true}>
          <StaticImage
            placeholder="blurred"
            width={700}
            src="../../images/InnovationImg.png"
            quality="70"
            alt=""
          />
        </Fade>
      </div>
    </ContainerInnovation>
  );
};
export default Innovation;
