import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import DoubleArrow from "../../images/svg/DoubleArrowIcon.svg";

const ContainerSlide = styled.div`
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid rgba(64, 87, 109, 0.07);
  border-radius: 0.2em;
  padding: 0.5em;
  background-color: #fff;

  #my-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: ${(props) => `url(${props.backgroundGrid})`};
  }

  .img-comp-slider {
    text-align: center;
    position: absolute;
    z-index: 9;
    cursor: ew-resize;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    top: 50%;
    margin-left: -14.5px;
  }
`;

const Slider = ({ imageBg, model }) => {
  const [sizes, setSizes] = useState(0.5);
  const dragElement = useRef();

  const { gridBackground } = useStaticQuery(
    graphql`
      query {
        gridBackground: file(relativePath: { eq: "Grid.jpg" }) {
          id
          publicURL
        }
      }
    `
  );

  useEffect(() => {
    if (dragElement.current == null) {
      return;
    }
  }, []);

  const slide = (xPosition) => {
    const containerBoundingRect = dragElement.current.getBoundingClientRect();
    setSizes(() => {
      if (xPosition < containerBoundingRect.left) {
        return 0;
      } else if (xPosition > containerBoundingRect.right) {
        return 1;
      } else {
        return (
          (xPosition - containerBoundingRect.left) / containerBoundingRect.width
        );
      }
    });
  };

  const handleMouseDown = () => {
    window.onmousemove = handleMouseMove;
    window.onmouseup = handleMouseUp;
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    slide(e.clientX);
  };
  const handleMouseUp = () => {
    window.onmousemove = undefined;
    window.onmouseup = undefined;
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    slide(e.touches[0].clientX);
  };
  const handleTouchUp = () => {
    window.ontouchmove = undefined;
    window.ontouchend = undefined;
  };

  const handleTouchStart = () => {
    window.ontouchmove = handleTouchMove;
    window.ontouchend = handleTouchUp;
  };

  return (
    <ContainerSlide backgroundGrid={gridBackground.publicURL}>
      <div
        ref={dragElement}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          userSelect: "none",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            userSelect: "none",
          }}
        >
          {imageBg}
        </div>
        <div
          id="my-img"
          style={{
            clipPath: `polygon(0 0, ${sizes * 100}% 0, ${
              sizes * 100
            }% 100%, 0 100%)`,
          }}
        >
          {model}
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: `${sizes * 100}%`,
          }}
        >
          <div
            style={{
              height: "100%",
              position: "relative",
              backgroundColor: "#fff",
              width: 2,
              marginLeft: -1.5,
            }}
          >
            <div
              onMouseDown={handleMouseDown}
              onTouchStart={handleTouchStart}
              className="img-comp-slider"
            >
              <DoubleArrow
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: 20,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ContainerSlide>
  );
};

export default Slider;
