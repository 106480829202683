import React from "react";
import styled from "@emotion/styled";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import KuuLogo from "../../images/svg/KuuLogo.svg";

const Container = styled.div`
  & h1 {
    background: linear-gradient(
      to left bottom,
      #f9993c,
      #fb9f33,
      #fca628,
      #fcad1a,
      #fbb400
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: clamp(1.4rem, 3vw, 1.8rem);
    font-weight: 700;
    margin: 0;
  }
  & h2 {
    margin: 0;
    margin-bottom: 1em;
    font-weight: 400;
    font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  }
`;

const HeaderApp = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div>
        <KuuLogo
          style={{
            maxWidth: 150,
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <h1>
        <Trans components={[<br />]}>{t("Photo & Video")}</Trans>
      </h1>
      <h2>
        <Trans
          components={[
            <br />,
            <strong
              style={{
                fontSize: "clamp(1.2rem, 2.5vw, 1.6rem)",
                fontWeight: "700",
              }}
            />,
          ]}
        >
          {t("Creation Platform")}
        </Trans>
      </h2>
    </Container>
  );
};
export default HeaderApp;
