import React from "react";
import styled from "@emotion/styled";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Container } from "@material-ui/core";
import axios from "axios";
import { FORM_SPREE } from "../config";
import {
  WrapperFieldSet,
  Label,
  Input,
  Textarea,
  Button,
  WrapperServerState,
  Select,
} from "./styles";

const Wrapper = styled.div`
  background-color: #333333;
  margin-top: 5rem;
  & h1 {
    margin: 0;
    line-height: 1.5;
    word-break: break-word;
    font-size: 1.5rem;
    color: #fff;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
`;
const ContactUs = () => {
  const { t } = useTranslation();

  const [input, setInputs] = React.useState({
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    businessEmail: "",
    message: "",
    APIselected: "",
  });
  const [serverState, setServerState] = React.useState({
    submitting: false,
    status: null,
  });

  const handleOnChange = (e) => {
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      setInputs({
        firstName: "",
        lastName: "",
        company: "",
        jobTitle: "",
        businessEmail: "",
        message: "",
        APIselected: "",
      });
    }

    setTimeout(() => {
      setServerState({
        submitting: false,
        status: null,
      });
    }, 5000);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setServerState({ submitting: true });
    axios({
      method: "POST",
      url: FORM_SPREE,
      data: input,
    })
      .then(() => {
        handleServerResponse(true, "Thanks for contacting us.");
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error);
      });
  };

  return (
    <div
      style={{
        padding: "1em",
        backgroundColor: "#fff",
        borderRadius: 5,
      }}
    >
      <Fade triggerOnce={true}>
        <form onSubmit={handleOnSubmit}>
          <WrapperFieldSet id="fs-frm-inputs">
            <div
              style={{
                display: "flex",
                gap: "0.5em",
              }}
            >
              <Label
                style={{
                  flex: 1,
                }}
              >
                {t("First Name")}

                <Input
                  required=""
                  type="text"
                  name="firstName"
                  id="firstName"
                  onChange={handleOnChange}
                  value={input.firstName}
                />
              </Label>
              <Label
                style={{
                  flex: 1,
                }}
              >
                {t("Last Name")}

                <Input
                  required=""
                  type="text"
                  name="lastName"
                  id="lastName"
                  onChange={handleOnChange}
                  value={input.lastName}
                />
              </Label>
            </div>

            <Label>
              {t("Company Name")}

              <Input
                required=""
                type="text"
                name="company"
                id="company"
                onChange={handleOnChange}
                value={input.company}
              />
            </Label>
            <Label>
              {t("Job Title")}

              <Input
                required=""
                type="text"
                name="jobTitle"
                id="jobTitle"
                onChange={handleOnChange}
                value={input.jobTitle}
              />
            </Label>
            <Label>
              {t("Business Email")}

              <Input
                required
                type="businessEmail"
                name="businessEmail"
                id="businessEmail"
                onChange={handleOnChange}
                value={input.businessEmail}
              />
            </Label>

            <Label>
              {t("Interest in API Service")}

              <Select
                required
                name="APIselected"
                id="APIselected"
                onChange={handleOnChange}
                value={input.APIselected}
              >
                <option value="" disabled hidden>
                  {t("Select")}
                </option>
                <option value="API_Image_Generation">
                  {t("AI Image Generation API")}
                </option>
                <option value="API_background_Removal">
                  {t("AI background Removal API")}
                </option>
                <option value="API_Smart_AI_Search">
                  {t("Smart AI Search")}
                </option>
              </Select>
            </Label>

            <Label>
              {t("Message")}

              <Textarea
                required
                name="message"
                id="message"
                onChange={handleOnChange}
                value={input.message}
                rows="8"
              />
            </Label>
          </WrapperFieldSet>
          {serverState.status && (
            <WrapperServerState
              errorMsg={!serverState.status.ok ? "errorMsg" : ""}
            >
              {serverState.status.msg}
            </WrapperServerState>
          )}
          <Button
            type="submit"
            value={t("Submit")}
            disabled={serverState.submitting}
          />
        </form>
      </Fade>
    </div>
  );
};

const WrapperContract = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <div
          style={{
            maxWidth: "1100px",
            margin: "auto",
            paddingTop: "5em",
            paddingBottom: "2em",
          }}
        >
          <Fade triggerOnce={true}>
            <h1>{t("Contact us")}</h1>
          </Fade>
        </div>
        <div
          style={{
            margin: "auto",
            maxWidth: 600,
          }}
        >
          {children}
        </div>
      </Container>
    </Wrapper>
  );
};

export { ContactUs, WrapperContract };
