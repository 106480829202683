import styled from "@emotion/styled";

export const WrapperElement = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  @media (max-width: 960px) {
    display: none;
  }
`;

export const HeaderPortfolio = styled.div`
  max-width: 1000px;
  margin: 4em auto;
  width: 100%;
  min-height: 350px;
  text-align: center;
  justify-content: center;
  grid-gap: 1.5em;
  display: grid;
  grid-auto-flow: column;
  overflow: hidden;
  @media (max-width: 960px) {
    margin-bottom: 8em;
  }
`;

export const SubHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0em 1em;

  & h1 {
    font-size: clamp(2rem, 3.5vw, 3.5rem);
    margin: 0;
  }
  & h2 {
    font-size: clamp(1rem, 2.5vw, 1rem);
    font-weight: 300;
  }
`;
