import styled from "@emotion/styled";

const WrapperHeader = styled.div`
  max-width: 1200px;
  margin: 2em auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 1rem;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & h1 {
    font-size: clamp(2rem, 2.5vw, 2.5rem);
  }

  & h2 {
    font-size: clamp(1rem, 2.5vw, 1.3rem);
    font-weight: 300;
  }

  .header-container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    ::before {
      content: " ";
      position: absolute;
      background-image: ${(props) => ` url(${props.backgroundImg})`};
      top: 5%;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: top center;
      z-index: -1;
    }
  }
`;

const WrapperQuote = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5em;
  position: relative;
  z-index: 1;
  ::before {
    content: " ";
    background-image: ${(props) => ` url(${props.backgroundImg})`};
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: -1;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  & h1 {
    letter-spacing: 10px;
    text-transform: uppercase;
    line-height: 1.5;
    font-size: clamp(1.7rem, 2.7vw, 2.4rem);
    font-weight: 900;
  }
`;

const ContainerInnovation = styled.div`
  position: relative;
  display: grid;
  padding-top: 2em;

  ::before {
    content: " ";
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f8f9fb;
    z-index: 0;
  }
`;

export { WrapperQuote, WrapperHeader, ContainerInnovation };
