import React from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { ContainerInfo } from "./styles";

const CompanyInfo = () => {
  const { t } = useTranslation();
  return (
    <ContainerInfo>
      <div className="container-company">
        <div
          style={{
            maxWidth: 700,
            width: "100%",
            margin: "0 auto",
            paddingTop: "2em",
          }}
        >
          <Fade triggerOnce={true}>
            <h1>
              <Trans components={[<strong style={{ color: "#fbb400" }} />]}>
                {t("company")}
              </Trans>
            </h1>
            <h2>
              <Trans components={[<strong />]}>{t("Kunana.ai")}</Trans>
            </h2>
          </Fade>
        </div>
        <div
          style={{
            maxWidth: 700,
            width: "100%",
            margin: "0 auto",
            textAlign: "center",
            paddingBottom: "2em",
          }}
        >
          <StaticImage
            width={500}
            src="../../images/kunana_logo.png"
            quality="50"
            alt=""
            placeholder="blurred"
          />
        </div>
      </div>
    </ContainerInfo>
  );
};
export default CompanyInfo;
