import styled from "@emotion/styled";

export const Divider = styled.div`
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "150px")};
  position: relative;
  ::after {
    content: " ";
    position: absolute;
    background: #323232;
    left: 0;
    width: 100%;
    height: 1px;
    top: 0;
  }
`;
