import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import MultilingualSearch from "../../images/Video/MultilingualSearch.mp4";
import { VideoFeature } from "../VideoContainer";
import { WrapperSmartMulti } from "./style";
import SmartIcon from "../../images/svg/SmartIcon.svg";
import { CustomButtonIcon } from "./buttonIcon";
import { Divider } from "../style";
import { TextContainer } from "./Components/Style";
import DialogContact from "./Components/Dialog";

const SmartMultilingual = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <WrapperSmartMulti>
      <div className="text-container">
        <Fade direction="up" triggerOnce={true}>
          <div className="title">
            <CustomButtonIcon
              icon={
                <SmartIcon
                  style={{
                    maxWidth: 30,
                    width: "100%",
                  }}
                />
              }
            />
            <h1>
              <Trans
                components={[
                  <br />,
                  <strong
                    style={{
                      color: "#fbb400",
                    }}
                  />,
                  <strong
                    style={{
                      fontSize: "clamp(1.2rem, 3.5vw, 1.7rem)",
                      fontWeight: 400,
                    }}
                  />,
                ]}
              >
                {t("Smart")}
              </Trans>
            </h1>

            <Divider />
          </div>
        </Fade>
        <Fade direction="up" triggerOnce={true}>
          <h2
            style={{
              fontSize: "clamp(1rem, 2.5vw, 1.1rem)",
              fontWeight: 300,
            }}
          >
            <Trans components={[<strong />]}>{t("With our latest")}</Trans>
          </h2>

          <h2
            style={{
              fontSize: "clamp(1rem, 2.5vw, 1.1rem)",
              fontWeight: 300,
            }}
          >
            {t("The smart multilingual")}
          </h2>
          <TextContainer>
            <div className="container">
              <p>
                <Trans
                  components={[
                    <br />,
                    <strong
                      style={{
                        fontWeight: 700,
                      }}
                    />,
                  ]}
                >
                  {t("work with us")}
                </Trans>
              </p>
              <button onClick={handleClickOpen}>{t("Contact us")}</button>
            </div>
          </TextContainer>
        </Fade>

        <DialogContact open={open} handleClose={handleClose} />
      </div>

      <div className="smart-image">
        <div
          style={{
            maxWidth: 400,
            width: "100%",
            background: "#fff",
            padding: "1em",
            border: "1px solid rgba(64, 87, 109, 0.07)",
            borderRadius: "0.5em",
            margin: "0 auto",
          }}
        >
          <Fade triggerOnce={true}>
            <VideoFeature
              videoName={MultilingualSearch}
              paddingTop="100%"
              controls={false}
              muted={true}
              autoPlay={true}
            />
          </Fade>
        </div>
      </div>
    </WrapperSmartMulti>
  );
};

export default SmartMultilingual;
