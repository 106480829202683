import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import { Divider } from "../style";
import { VisualContainer } from "./style";

const SaveVoiceProject = () => {
  const { t } = useTranslation();
  return (
    <>
      <VisualContainer>
        <div className="title">
          <Fade triggerOnce={true} direction="up">
            <h1>
              <Trans
                components={[<br />, <strong style={{ color: "#fbb400" }} />]}
              >
                {t("Voice analysis")}
              </Trans>
            </h1>
            <Divider />
          </Fade>
        </div>

        <div className="video-container">
          <Fade triggerOnce={true}>
            <div
              style={{
                maxWidth: 450,
                width: "100%",
                background: "#fff",
                padding: "0.5em",
                border: "1px solid rgba(64, 87, 109, 0.07)",
                borderRadius: "0.5em",
                margin: "0 auto",
                aspectRatio: 16 / 9,
              }}
            >
              <StaticImage
                placeholder="blurred"
                width={450}
                src="../../images/Vmagine/save_my_voice_thumbnail.jpg"
                quality="60"
                alt=""
              />
              {/* TODO add path video here */}
              {/* <VideoWithThumbnail
                thumbnail={ThumbnailVisible}
                videoName={cannes}
              /> */}
            </div>
          </Fade>
        </div>

        <div className="text-description">
          <Fade triggerOnce={true} direction="up">
            <h1
              style={{
                color: "#056B9F",
                fontSize: "clamp(1.2rem , 2.5vw, 1.5rem)",
                fontWeight: 800,
              }}
            >
              {t("Save My Voice Project")}
            </h1>
            <p
              style={{
                fontSize: "clamp(1rem, 2.5vw, 1rem)",
                fontWeight: 300,
              }}
            >
              <Trans components={[<br />]}>
                {t("Many deaf or hard-of-hearing")}
              </Trans>
            </p>
            <p
              style={{
                fontSize: "clamp(1rem, 2.5vw, 1rem)",
                fontWeight: 300,
              }}
            >
              <Trans components={[<br />]}>{t("With the collaboration")}</Trans>
            </p>
          </Fade>
        </div>

        <div className="image-container">
          <Fade triggerOnce={true}>
            <div
              style={{
                maxWidth: "600px",
                width: "100%",
                margin: "0 auto",
              }}
            >
              <p
                style={{
                  fontSize: "clamp(1rem, 2.5vw, 1rem)",
                  fontWeight: 300,
                }}
              >
                <Trans components={[<br />]}>
                  {t("We used AI technologies")}
                </Trans>
              </p>
              <p
                style={{
                  fontSize: "clamp(1rem, 2.5vw, 1rem)",
                  fontWeight: 300,
                }}
              >
                <Trans components={[<br />]}>{t("With this platform")}</Trans>
              </p>
            </div>
          </Fade>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "2em",
            }}
          >
            <Fade triggerOnce={true} direction="up">
              <StaticImage
                placeholder="blurred"
                height={65}
                src="../../images/Vmagine/save_my_voice_logo.png"
                quality="50"
                alt=""
              />
              <StaticImage
                placeholder="blurred"
                height={65}
                src="../../images/Vmagine/thai_health_logo.png"
                quality="50"
                alt=""
              />
            </Fade>
          </div>
        </div>
      </VisualContainer>
    </>
  );
};

export default SaveVoiceProject;
