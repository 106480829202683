import React from "react";
import Header from "./Header";
import Innovation from "./Innovation";

const Banner = ({ bgElement }) => {
  return (
    <>
      <Header backgroundImg={bgElement} />
      <Innovation />
    </>
  );
};
export default Banner;
