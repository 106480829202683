import React from "react";
import { graphql } from "gatsby";
import Layout from "../Components/Navigation/index";
import SEO from "../Components/SEO/index";
import PortfolioContent from "../Components/Portfolio/PortfolioContent";
import Banner from "../Components/Home/index";
import CompanyContainer from "../Components/Company/index";
import { WrapperContract, ContactUs } from "../Components/ContactUs/index";
import Footer from "../Components/Footer";
import ShowCaseContainer from "../Components/ShowCase/index";
import AppCreator from "../Components/Mobile/AppCreator";
import BannerVideo from "../images/Video/VideoBanner.mp4";
import AdvertiseAppCreator from "../Components/Mobile/AdvertiseAppCreator";
import VmagineApp from "../Components/VmagineApp";

const IndexPage = ({ data: { bannerElement } }) => {
  return (
    <Layout>
      <SEO />
      <section id="home">
        <Banner bgElement={bannerElement.publicURL} />
      </section>
      <section id="portfolio">
        <PortfolioContent />
        <ShowCaseContainer />
        <VmagineApp />

        <AppCreator srcVideo={BannerVideo} />
        <AdvertiseAppCreator />
      </section>
      <section id="company">
        <CompanyContainer />
      </section>

      <section id="contact">
        <div
          style={{
            backgroundColor: "#333333",
          }}
        >
          <WrapperContract>
            <ContactUs />
          </WrapperContract>
          <Footer />
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    bannerElement: file(relativePath: { eq: "svg/BannerElement.svg" }) {
      id
      publicURL
    }
  }
`;
