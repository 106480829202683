import React from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import Video from "./VideoApp";
import AppStore from "../AppStore";
import {
  AppCreatorVideo,
  AppCreatorVideoContainer,
  AppCreatorText,
} from "./style";
import HeaderApp from "./HeaderApp";
import { Divider } from "../style";

const AppCreator = ({ srcVideo }) => {
  const { t } = useTranslation();
  const { quoteGradient } = useStaticQuery(
    graphql`
      query {
        quoteGradient: file(relativePath: { eq: "svg/bgKUUCreator.svg" }) {
          id
          publicURL
        }
      }
    `
  );

  return (
    <AppCreatorVideo>
      <AppCreatorVideoContainer backgroundImg={quoteGradient.publicURL}>
        <Fade triggerOnce={true}>
          <Video srcVideo={srcVideo} />
        </Fade>
      </AppCreatorVideoContainer>

      <div className="text-app-creator">
        <Fade direction="up" triggerOnce={true}>
          <HeaderApp />
          <Divider />
          <AppCreatorText>
            <h1>{t("KUU Creator App")}</h1>
            <h2>
              <Trans components={[<br />]}>{t("Compose images")}</Trans>
            </h2>
            <AppStore />
          </AppCreatorText>
        </Fade>
      </div>
    </AppCreatorVideo>
  );
};
export default AppCreator;
