import React from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import PeopleIcon from "../../images/svg/PeopleIcon.svg";
import MyCutoutIcon from "../../images/svg/MyCutoutIcon.svg";
import ObjectIcon from "../../images/svg/ObjectIcon.svg";
import VideoEffectIcon from "../../images/svg/VideoEffectIcon.svg";
import VideoStickerIcon from "../../images/svg/VideoStickerIcon.svg";
import BgIcon from "../../images/svg/BgIcon.svg";
import AnimationIcon from "../../images/svg/AnimationIcon.svg";
import PlayIcon from "../../images/svg/PlayIcon.svg";
import OvalOuterElement from "../../images/svg/OvalOuter.svg";
import OvalInnerElement from "../../images/svg/OvalInner.svg";

const WrapperOuterCircle = styled.div`
  position: relative;
  transform: translate(8%, 0%);
`;

const WrapperInnerCircle = styled.div`
  right: 0;
  top: 0;
  transform: translate(80%, 0%);
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const rotation = keyframes`
from {
				-webkit-transform: rotate(0deg);
		}
		to {
				-webkit-transform: rotate(359deg);
		}
`;

const reverseRotation = keyframes`
from {
				-webkit-transform: rotate(0deg);
		}
		to {
				-webkit-transform: rotate(-359deg);
		}
`;

const CircleOuterRotate = styled.div`
  animation: ${rotation} 35s infinite linear;
`;

const CircleInnerRotate = styled.div`
  animation: ${reverseRotation} 35s infinite linear;
`;

const AnimationContainer = styled.div`
  display: flex;
  position: relative;
`;

const ElementContainer = () => {
  return (
    <AnimationContainer>
      <WrapperOuterCircle>
        <div
          style={{
            position: "absolute",
            top: "0%",
            left: "30%",
            zIndex: 1,
          }}
        >
          <PlayIcon />
        </div>
        <div
          style={{
            position: "absolute",
            top: "17%",
            left: "5%",
            zIndex: 1,
          }}
        >
          <VideoStickerIcon />
        </div>

        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "-3%",
            zIndex: 1,
          }}
        >
          <VideoEffectIcon />
        </div>

        <div
          style={{
            position: "absolute",
            top: "65%",
            left: "3%",
            zIndex: 1,
          }}
        >
          <AnimationIcon />
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "0%",
            left: "30%",
            zIndex: 1,
          }}
        >
          <BgIcon />
        </div>

        <CircleOuterRotate>
          <OvalOuterElement
            style={{
              zIndex: 0,
              padding: "1.5em 0em",
            }}
          />
        </CircleOuterRotate>
      </WrapperOuterCircle>
      <WrapperInnerCircle>
        <div
          style={{
            position: "absolute",
            top: "25%",
            left: "0%",
            zIndex: 1,
          }}
        >
          <PeopleIcon />
        </div>

        <div
          style={{
            position: "absolute",

            top: "45%",
            left: "-13%",
            zIndex: 1,
          }}
        >
          <MyCutoutIcon />
        </div>

        <div
          style={{
            position: "absolute",
            top: "64%",
            left: "0%",
            zIndex: 1,
          }}
        >
          <ObjectIcon />
        </div>

        <CircleInnerRotate>
          <OvalInnerElement
            style={{
              zIndex: 0,
            }}
          />
        </CircleInnerRotate>
      </WrapperInnerCircle>
    </AnimationContainer>
  );
};

export default ElementContainer;
