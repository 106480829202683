import React from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import CompanyInfo from "./CompanyInfo";
import CustomCard from "./Card";
import KunanaLogo from "../../images/svg/KunanaLogo.svg";
// import MeetTeam from "./MeetTeam";

const ContainerLogo = styled.div`
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  padding: 3em 0em;
  margin-top: 3em;
  position: relative;
  z-index: 1;
  text-align: center;
  ::after {
    content: " ";
    background-image: ${(props) => ` url(${props.backgroundImg})`};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    z-index: -1;
  }
`;

const CompanyContainer = () => {
  const { t } = useTranslation();
  const { bannerElement } = useStaticQuery(
    graphql`
      query {
        bannerElement: file(relativePath: { eq: "svg/BannerElement.svg" }) {
          id
          publicURL
        }
      }
    `
  );
  return (
    <>
      <CompanyInfo />

      {/* <MeetTeam /> */}

      <Fade triggerOnce={true}>
        <h1
          style={{
            textAlign: "center",
            fontSize: "clamp(1.4rem, 3vw, 2.9rem)",
          }}
        >
          <Trans components={[<strong tyle={{ color: "#fbb400" }} />]}>
            {t("Values We Live By")}
          </Trans>
        </h1>
      </Fade>

      <Fade direction="up" triggerOnce={true}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            boxSizing: "border-box",
            width: "100%",
            margin: "auto",
            maxWidth: 1200,
          }}
        >
          <CustomCard
            icon={
              <StaticImage
                height={150}
                src="../../images/StriveIcon.png"
                quality="50"
                alt=""
                placeholder="blurred"
              />
            }
            header={t("strive for the best")}
            subHeader=""
            description={t("We give our")}
          />

          <CustomCard
            icon={
              <StaticImage
                height={150}
                src="../../images/KeepIcon.png"
                quality="50"
                alt=""
                placeholder="blurred"
              />
            }
            header={t("keep learning")}
            subHeader=""
            description={t("We embrace the")}
          />

          <CustomCard
            icon={
              <StaticImage
                height={150}
                src="../../images/CreativeIcon.png"
                quality="50"
                alt=""
                placeholder="blurred"
              />
            }
            header={t("have creativity & fun")}
            subHeader=""
            description={t("We love what")}
          />

          <CustomCard
            icon={
              <StaticImage
                height={150}
                src="../../images/HumanIcon.png"
                quality="50"
                alt=""
                placeholder="blurred"
              />
            }
            header={t("be good humans")}
            subHeader=""
            description={t("We value the great")}
          />
        </div>
      </Fade>

      <Fade triggerOnce={true}>
        <ContainerLogo backgroundImg={bannerElement.publicURL}>
          <KunanaLogo
            style={{ maxWidth: 300, width: "100%", height: "100%" }}
          />
        </ContainerLogo>
      </Fade>
    </>
  );
};
export default CompanyContainer;
