import React from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { VideoFeature } from "../VideoContainer";
import AIDrawVideo from "../../images/Video/Vmagine/draw.mp4";
import {
  TypographyGradient,
  ContainerGrid,
  ContainerVideo,
  ContainerTypography,
} from "./Style";

const AIArtDrawingContent = () => {
  const { t } = useTranslation();
  return (
    <ContainerGrid
      backgroundGradient={[
        "linear-gradient(290deg, rgba(21,21,21,1) 0%, rgba(23,23,23,1) 50%, rgba(65,15,15,1) 100%)",
        "linear-gradient(180deg, rgba(21,21,21,1) 0%, rgba(23,23,23,1) 50%, rgba(65,15,15,1) 100%)",
      ]}
    >
      <ContainerVideo order="true">
        <div className="container-video">
          <div
            style={{
              maxWidth: 400,
              width: "100%",
              border: "1px solid rgba(64, 87, 109, 0.07)",
              borderRadius: "0.5em",
              margin: "0 auto",
            }}
          >
            <VideoFeature
              videoName={AIDrawVideo}
              paddingTop="100%"
              controls={false}
              muted={true}
              autoPlay={true}
            />
          </div>
        </div>
      </ContainerVideo>
      <ContainerTypography>
        <Fade triggerOnce={true} direction="up">
          <h1>
            <Trans
              components={[
                <br />,
                <strong
                  style={{
                    fontSize: "clamp(1.8rem, 3vw, 2.2rem)",
                    color: "#F09819",
                    fontWeight: 700,
                    backgroundImage: "linear-gradient(45deg, #F09819, #FFB458)",
                    backgroundSize: "100%",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    MozBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    MozTextFillColor: "transparent",
                  }}
                />,
              ]}
            >
              {t("Turn Drawing into")}
            </Trans>
          </h1>
          <TypographyGradient
            text={t("AI Art")}
            linearGradient="linear-gradient(45deg, #F09819, #FFB458)"
          />
          <p>
            <Trans
              components={[
                <br />,
                <strong
                  style={{
                    fontWeight: 700,
                  }}
                />,
              ]}
            >
              {t("Draw a simple image")}
            </Trans>
          </p>
        </Fade>
      </ContainerTypography>
    </ContainerGrid>
  );
};

export default AIArtDrawingContent;
