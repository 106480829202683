import React, { useState } from "react";
import { useTransition, config, animated } from "react-spring";
import { useTranslation } from "gatsby-plugin-react-i18next";
import LottieContent from "../Lottie/index";
import { WrapperHeader } from "./styles";

const Header = ({ backgroundImg }) => {
  const { t } = useTranslation();
  const [toggle, set] = useState(false);
  const transitions = useTransition(toggle, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: toggle,
    delay: 3000,
    config: config.gentle,
    onRest: () => set(!toggle),
  });

  return (
    <WrapperHeader backgroundImg={backgroundImg}>
      <div className="header-container">
        <h1 style={{ margin: 0, fontWeight: 800 }}>
          {t("AI Technology Solutions")}
          <br />
        </h1>
        <h1
          style={{
            position: "relative",
            margin: 0,
            fontWeight: 800,
          }}
        >
          {t("for")}
          {transitions(({ opacity }, item) =>
            item ? (
              <animated.div
                style={{
                  position: "absolute",
                  color: "#fbb400",
                  opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                  top: 0,
                  left: "70px",
                }}
              >
                {t("BUSINESS")}
              </animated.div>
            ) : (
              <animated.div
                style={{
                  position: "absolute",
                  color: "#fbb400",
                  opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
                  top: 0,
                  left: "70px",
                }}
              >
                {t("LIFE")}
              </animated.div>
            )
          )}
        </h1>

        <h1 style={{ margin: 0, fontWeight: 800 }}>{t("Transformation")}</h1>
        <h2>{t("KUNANA is a Software")}</h2>
      </div>

      <div
        style={{
          padding: "1em",
        }}
      >
        <LottieContent
          style={{
            width: "100%",
            height: "100%",
          }}
          pathName="HomeAnimation.json"
        />
      </div>
    </WrapperHeader>
  );
};
export default Header;
