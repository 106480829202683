import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Fade } from "react-awesome-reveal";
import Banner from "./Banner";
import AmazingArtContent from "./AmazingArtContent";
import RecreateTitle from "./RecreateTitle";
import AIArtContent from "./AIArtContent";
import AIArtDrawingContent from "./AIArtDrawingContent";
import AIArtOtherContent from "./AIArtOtherContent";
import AIAvatar from "./AIAvatar.js";
import AppStoreButton from "./AppStoreButton";
import TitleArticle from "../ShowCase/Components/TitleArticle";

const VmagineApp = () => {
  const { t } = useTranslation();
  return (
    <>
      <TitleArticle title={t("Kunana Mobile Application Platform")} />
      <div
        style={{
          backgroundColor: "#1e1e1e",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "3em",
        }}
      >
        <Banner />
        <AmazingArtContent />
        <RecreateTitle />
        <AIArtContent />
        <AIArtDrawingContent />
        <AIArtOtherContent />
        <AIAvatar />
        <Fade triggerOnce={true}>
          <div
            style={{
              margin: "0 auto",
              textAlign: "center",
              padding: "3em 0em",
            }}
          >
            <AppStoreButton hiddenButton={true} />
          </div>
        </Fade>
      </div>
    </>
  );
};

export default VmagineApp;
