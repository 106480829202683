import React from "react";
import { Typography, Container } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";
import styled from "@emotion/styled";

const StyledLink = styled(Link)`
  color: #bfbfbf;
  font-size: 0.8rem;
  text-decoration: none;
  margin-bottom: 0.35em;
`;

const CopyRight = () => (
  <Typography
    variant="body2"
    style={{
      color: "#BFBFBF",
      fontSize: "0.7rem",
      marginRight: "auto",
      lineHeight: 3,
      marginLeft: "5.8em",
    }}
  >
    ©{new Date().getFullYear()} Kunana Co.,Ltd. - All Rights Reserved
  </Typography>
);

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        background: "#333333",
        padding: "3em 0em",
      }}
    >
      <Container
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: 600,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <StyledLink to="/">{t("Home")}</StyledLink>
          <StyledLink to="/privacy">{t("Privacy Policy")}</StyledLink>
        </div>
        <div>
          <Typography
            variant="body2"
            style={{
              color: "#BFBFBF",
              fontSize: "0.8rem",
              maxWidth: 200,
              marginRight: "auto",
              marginLeft: "5em",
            }}
          >
            Kunana Co.,Ltd.
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: "#BFBFBF",
              fontSize: "0.8rem",
              maxWidth: 200,
              marginRight: "auto",
              marginLeft: "5em",
              padding: "1em 0em",
            }}
          >
            740 Phaholyothin 30,
            <br />
            Chatuchak, Bangkok 10900
            <br /> Thailand
          </Typography>
          <CopyRight />
        </div>
      </Container>
    </div>
  );
};

export default Footer;
