import styled from "@emotion/styled";

const WrapperFieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 0px;
`;
const WrapperForm = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;
`;
const Label = styled.label`
  display: flex;
  flex-flow: column;
  padding: 1rem;
  @media (max-width: 623px) {
    padding: 0rem;
  }
`;
const Input = styled.input`
  margin: 0.5rem 0rem;
  padding: 0.5rem;
  background: #eaeaea;
  border-radius: 5px;
  border-color: transparent;
  width: 100%;
`;
const Textarea = styled.textarea`
  margin: 1rem 0rem;
  border-radius: 5px;
  background: #eaeaea;
  border-color: transparent;
  width: 100%;
`;
const Button = styled.input`
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  margin-left: auto;
  display: flex;
  background: #3a3a3a;
  border-radius: 5px;
  border: 0px;
  color: #fff;
  cursor: pointer;
`;
const WrapperServerState = styled.p`
  padding: 1em;
  text-align: center;
`;

const Select = styled.select`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  padding: 0.7em;
  margin: 0.5rem 0rem;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;

  :not(:valid) {
    color: gray;
  }
`;

export {
  WrapperServerState,
  WrapperFieldSet,
  WrapperForm,
  Label,
  Input,
  Textarea,
  Button,
  Select,
};
