import styled from "@emotion/styled";

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 5em auto;
`;
export const HeaderContainer = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 3em auto;
  text-align: center;
  position: relative;

  .text-show-case {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & h1 {
      font-size: clamp(1.4rem, 3.5vw, 1.6rem);
      margin: 0em 0em;
      font-weight: 800;
    }
  }
`;

export const ButtonCustom = styled.a`
  all: unset;
  width: 100%;
  max-width: 130px;
  margin: 0 0 1em auto;
  margin-right: 2em;
  font-size: clamp(0.9rem, 3.5vw, 0.9rem);
  background-color: #e2f5ff;
  border-radius: 25px;
  max-width: 130px;
  text-align: center;
  padding: 0.5em;
  color: #2598da;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const InformationContainer = styled.div`
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  ::after {
    content: " ";
    position: absolute;
    background-image: ${(props) => (props.bgColor ? props.bgColor : "")};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .wrapper-container {
    margin: 0em auto;
    max-width: 900px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    grid-gap: 1em;
    padding: 4em 2em;
  }
  .wrapper-container-missing {
    margin: 0em auto;
    max-width: 900px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    grid-gap: 1em;
    padding: 4em 2em;
    align-items: center;
    @media (max-width: 623px) {
      grid-gap: 2em;
    }
  }

  .text-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    ::after {
      content: "Publication";
      background-color: #000;
      color: #fff;
      position: absolute;
      top: ${(props) => (props.topAfter ? props.topAfter : "-5px")};
      left: 5%;
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ::before {
      content: " ";
      border: 1px solid rgba(64, 87, 109, 0.07);
      border-radius: 0.5em;
      position: absolute;
      top: ${(props) => (props.top ? props.top : "15px")};
      left: 0;
      height: ${(props) => (props.height ? props.height : "90%")};
      width: 100%;
      transition: box-shadow 0.3s;
      background-color: #fff;
      z-index: -1;
    }
    :hover::before {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
    @media (max-width: 623px) {
      ::before {
        height: 100%;
      }
    }
  }

  .text-container {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    padding: 4em 1em 0em 1em;
  }
`;

export const WrapperSmartMulti = styled.div`
  position: relative;
  z-index: 1;
  padding: 3em 0em;
  margin: 2em auto;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1.5em;
  .smart-image {
    display: flex;
    align-items: center;
    grid-column: 3 / span 3;
    padding: 2em 1em;
    background-image: linear-gradient(
      to right top,
      #485563,
      #404c59,
      #38434f,
      #313a45,
      #29323c
    );
  }
  .text-container {
    align-self: center;
    grid-column: 1 / span 2;
    padding: 0em 3em;
  }
  .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    & h1 {
      font-size: clamp(1.5rem, 2.5vw, 2.6rem);
      font-weight: 700;
      margin: 0.5em 0em;
    }
  }
  @media (max-width: 786px) {
    .smart-image {
      grid-column: 1 / span 5;
    }
    .title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .text-container {
      grid-column: 1 / span 5;
    }
  }
`;

export const VisualContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0em auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  .title {
    grid-column: 1 / span 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0em 3em;
    padding-bottom: 2em;
    background-color: #f9f9f9;
    & h1 {
      font-size: clamp(1.5rem, 2.5vw, 2.6rem);
      margin: 0.5em 0em;
    }
  }

  .video-container {
    grid-column: 3 / span 3;
    padding: 3em 0em;
    background-image: linear-gradient(
      to left top,
      #149ae3,
      #1091d5,
      #0d88c7,
      #0b7fb9,
      #0a76ab
    );
  }
  .text-description {
    padding: 0em 3em;
    padding-bottom: 2em;
    grid-column: 1 / span 2;
    background-color: #f9f9f9;
  }
  .visual-tech {
    padding-bottom: 2em;
  }
  .image-container {
    grid-column: 3 / span 3;
    background-image: linear-gradient(
      to right top,
      #485563,
      #404c59,
      #38434f,
      #313a45,
      #29323c
    );

    color: #fff;
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    text-align: center;
    padding: 2em 1em;
    @media (max-width: 780px) {
      grid-auto-flow: row;
    }
  }
  .logo-container {
    text-align: center;
  }
  @media (max-width: 786px) {
    .title {
      grid-column: 1 / span 5;
      align-items: center;
      text-align: center;
      padding-top: 2em;
    }
    .video-container {
      grid-column: 1 / span 5;
      grid-row: 3;
    }
    .text-description {
      grid-column: 1 / span 5;
    }
    .image-container {
      grid-column: 1 / span 5;
    }
  }
`;
