import { StaticImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";
import { Trans } from "gatsby-plugin-react-i18next";
import { Container, HeaderContainer } from "../style";

const TitleArticle = (props) => {
  return (
    <Container>
      <HeaderContainer>
        <StaticImage
          placeholder="blurred"
          height={450}
          src="../../../images/circleElement.png"
          quality="50"
          alt=""
        />
        <div className="text-show-case">
          <Fade triggerOnce={true}>
            <h1>
              <Trans
                components={[
                  <br />,
                  <strong
                    style={{
                      fontWeight: "800",
                      margin: "0em 0em",
                      fontSize: "clamp(2rem, 3.1vw, 3.5rem)",
                      background:
                        "linear-gradient(to left bottom,#f9993c,#fb9f33,#fca628,#fcad1a,#fbb400)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  />,
                  <strong
                    style={{
                      fontSize: "clamp(1.5rem, 3.5vw, 2.4rem)",
                      margin: 0,
                      fontWeight: 600,
                      color: "#000",
                    }}
                  />,
                ]}
              >
                {props.title}
              </Trans>
            </h1>
          </Fade>
        </div>
      </HeaderContainer>
    </Container>
  );
};

export default TitleArticle;
